import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import React from 'react'

import LinkCTA from '../UI/LinkCTA'
import Wrapper from '../UI/Wrapper'
import { ContentBox, IconBox, IconGrid, OverviewSection } from './styles'

interface ProgramOverviewProps {
  content: {
    headline: string
    body: string
    ctaText: string
    ctaTarget: string
    iconCopySet: {
      id: string
      icon: {
        description: string
        imgixImage: any
      }
      subhead: string
      body: string[]
    }[]
  }
}

interface IconCopySetProps {
  item: {
    id: string
    icon: {
      description: string
      imgixImage: any
    }
    subhead: string
    body: string[]
  }
}

const IconSet = (props: IconCopySetProps) => {
  const { item } = props
  const image = getImage(item?.icon?.imgixImage)

  return (
    <IconBox>
      <div className="imageContainer">
        {image ? (
          <GatsbyImage alt={item?.icon?.description || ''} image={image} />
        ) : null}
      </div>
      {item?.subhead ? parse(item.subhead) : null}
      {item?.body?.map((subItem: string) => (
        <div key={`iconlist-${subItem}`}>{subItem}</div>
      ))}
    </IconBox>
  )
}

const ProgramOverview = (props: ProgramOverviewProps) => {
  const { content } = props
  const ctaText = content?.ctaText?.replace(/(<([^>]+)>)/gi, '') || ''

  return (
    <OverviewSection component="section">
      <Wrapper>
        <Grid container justifyContent="space-between">
          <ContentBox>
            <Typography
              className="headline"
              color="secondary"
              component="h2"
              style={{ marginBottom: '32px' }}
              variant="h2"
            >
              {content?.headline}
            </Typography>
            <Typography
              component="p"
              style={{ marginBottom: '40px' }}
              variant="body1"
            >
              {parse(content?.body)}
            </Typography>
            <div className="desktopCtaContainer">
              <LinkCTA arrow={true} directUrl={content?.ctaTarget}>
                {ctaText}
              </LinkCTA>
            </div>
          </ContentBox>
          <IconGrid container item>
            {content?.iconCopySet?.map((item: any) => (
              <IconSet item={item} key={item.id} />
            ))}
          </IconGrid>
        </Grid>
        <Grid className="mobileCtaContainer" container justifyContent="center">
          <LinkCTA arrow={true} directUrl={content?.ctaTarget}>
            {ctaText}
          </LinkCTA>
        </Grid>
      </Wrapper>
    </OverviewSection>
  )
}

export default ProgramOverview
